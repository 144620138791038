export const views = [
	[
		[
			[["1", "Edit..."], ""],
			[["2", "SortA("], ""],
			[["3", "SortD("], ""],
			[["4", "ClrList"], ""],
			[["5", "SetUpEditor"], ""],
		],
		"",
	],
	[
		[
			[["1", "1-Var Stats"], ""],
			[["2", "2-Var Stats"], ""],
			[["3", "Med-Med"], ""],
			[["4", "LinReg(ax+b)"], ""],
			[["5", "QuadReg"], ""],
			[["6", "CubicReg"], ""],
			[["7", "QuartReg"], ""],
			[["8", "LinReg(a+bx)"], ""],
			[["9", "LnReg"], ""],
			[["0", "ExpReg"], ""],
			[["A", "PwrReg"], ""],
			[["B", "Logistic"], ""],
			[["C", "SinReg"], ""],
			[["D", "Manual-Fit Y=mX+b"], ""],
			[["E", "QuickPlot&Fit-EQ"], ""],
		],
		"",
	],
	[
		[
			[["1", "Z-Test..."], ""],
			[["2", "T-Test..."], ""],
			[["3", "2-SampZTest..."], ""],
			[["4", "2-SampTTest..."], ""],
			[["5", "1-PropZTest..."], ""],
			[["6", "2-PropZTest..."], ""],
			[["7", "ZInterval..."], ""],
			[["8", "TInterval..."], ""],
			[["9", "2-SampZInt..."], ""],
			[["0", "2-SampTInt..."], ""],
			[["A", "1-PropZInt..."], ""],
			[["B", "2-PropZInt..."], ""],
			[["C", "X^2-Test..."], ""],
			[["D", "X^2GOF-Test..."], ""],
			[["E", "2-SampFTest..."], ""],
			[["F", "Lin-RegTTest..."], ""],
			[["G", "Lin-RegTInt..."], ""],
			[["H", "ANOVA("], ""],
		],
		"",
	],
];
